<!--
 * @Description: AKJERRT
 * @Date: 2022-07-12 17:20:47
 * @LastEditTime: 2022-07-14 14:34:29
 * @FilePath: \HuiLiang\affair\src\views\account_statement\accountStatementList.vue
-->
<template>
  <a-spin :spinning="spinning">
    <a-card :border="false">
      <div class="realtor">
        <!-- 查询表单 -->
        <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
          <a-row :gutter="8">
<!--            <a-col :md="8" :xl="6" :xxl="6" :sm="12">-->
<!--              <a-form-model-item label="大区事务所">-->
<!--                <a-cascader-->
<!--                  :options="dealerList"-->
<!--                  v-model="regionalOffice"-->
<!--                  change-on-select-->
<!--                  placeholder="请选择大区/事务所"-->
<!--                  @change="onChange"-->
<!--                  :field-names="{ label: 'title', value: 'id', children: 'children' }"-->
<!--                />-->
<!--              </a-form-model-item>-->
<!--            </a-col>-->
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="经销商">
                <a-input v-model="searchData.dealerName" placeholder="请输入经销商名称" allowClear></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="对账人">
                <a-input v-model="searchData.statementUser" placeholder="请输入对账人" allowClear></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="对账状态">
                <a-select placeholder="请选择" v-model="searchData.confirmStatus" allowClear>
                  <a-select-option :value="0">未确认</a-select-option>
                  <a-select-option :value="1">相符</a-select-option>
                  <a-select-option :value="2">不相符</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
<!--              <a-form-model-item label="对账时间">-->
<!--                <a-month-picker placeholder="请选择对账时间" />-->
<!--              </a-form-model-item>-->
              <a-form-model-item label="对账月份">
                <MonthPicker :startTime.sync="searchData.checkTime" />
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="5" :md="8" sm="2">
              <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                <a-button type="primary" @click="query()" icon="search">查询</a-button>
                <a-button type="default" @click="reset()" icon="sync">重置</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <div>
          <a-button v-if="isCheck" type="primary" @click="hanlderTest('check')"> 查看 </a-button>
          <a-button v-if="isLog" type="primary" @click="hanlderTest('checkLog')" icon="file">操作日志</a-button>
          <a-button v-if="isConfirm && selectedRows.length > 0 && !selectedRows[0].flagConfirm" type="primary" @click="hanlderTest('confirm')">确认</a-button>
          <a-button v-if="isConfirm && selectedRows.length > 0 && selectedRows[0].auditStatus == 3" type="primary" @click="hanlderTest('confirm')">重新确认</a-button>
          <a-button v-if="isUnConfirm" type="default" @click="hanlderTest('unConfirm')" icon="rollback">撤销确认</a-button>
<!--          <downLoad-->
<!--            method="get"-->
<!--            api="/api/order/order/orderInfo/exportSingleOrderInfo"-->
<!--            :params="{ id: 11 }"-->
<!--            name="导出.xlsx"-->
<!--            >导出</downLoad-->
<!--          >-->
        </div>
        <!-- 数据表格 -->
        <a-table
          :loading="tableLoading"
          :row-selection="rowSelection"
          :rowKey="(record) => record.id"
          :columns="columns"
          :data-source="tableData"
          bordered
          :customRow="changeTableRow"
          @change="changeTable"
          :pagination="page"
        >
          <span slot="amount" slot-scope="text"> {{ text | formatMoney }} </span>
          <span slot="confirmStatus" slot-scope="text,record">
            <a-tag color="#e93323" v-if="text == 0">{{record | formatterStatus}}</a-tag>
            <a-tag color="#67c23a" v-if="text == 1">相符</a-tag>
            <a-tag color="#e6a23c" v-if="text == 2">不相符</a-tag>
          </span>
          <span slot="auditStatus" slot-scope="text,record">
            <a-tag v-if="text == 0" color="red">{{record | formatterAudit}}</a-tag>
            <a-tag v-if="text == 1" color="pink">待审核</a-tag>
            <a-tag v-if="text == 2" color="green">审核通过</a-tag>
            <a-tag v-if="text == 3" color="orange">审核拒绝</a-tag>
          </span>
          <span slot="oweAmount" slot-scope="text,record">
            <span class="TextRed" @click="handlerTableClick('TextRed',record)">{{ text | formatMoney }}</span>
          </span>
          <span slot="totalAmount" slot-scope="text">
            <span class="DarkGreen">{{ text | formatMoney }}</span>
          </span>
          <span slot="balanceAmount" slot-scope="text,record">
            <span class="ShallowGreen" @click="handlerTableClick('ShallowGreen',record)">{{ text | formatMoney }}</span>
          </span>
          <span slot="limitAmount" slot-scope="text,record">
            <span class="ShallowGreen" @click="handlerTableClick('ShallowGreenXY',record)">{{ text | formatMoney }}</span>
          </span>
          <span slot="CKDZD" slot-scope="text,record">
            <span class="DarkBule" @click="handlerTableClick('DarkBule',record)">对账单</span>
          </span>
          <div slot="statementFile" slot-scope="text" class="img-list">
            <div v-if="text" class="img-box">
              <img
                class="img-url"
                v-if="isImgFile(text)"
                width="100%"
                height="100%"
                :src="text"
                alt="无账单附件"
                @click="handlerEnlarge(text)"
              />
              <a v-else :href="text" target="_blank" class="buttonText">查看附件</a>
            </div>
          </div>
        </a-table>
      </div>
      <Overview ref="overview" />
      <ZJAccountInfo ref="ZJAccountInfo" />
      <XYAccountInfo ref="XYAccountInfo" />
      <QKAccountInfo ref="QKAccountInfo" />
      <!-- 查看详情 -->
      <AccountCheckModal ref="accountCheckModal" />
      <!-- 确认弹窗 -->
      <Confirm ref="confirmDom" @reload="getData"/>
      <!-- 附件抽屉 -->
      <Drawer ref="drawer" />
      <OperateLog ref="OperateLog"></OperateLog>
    </a-card>
  </a-spin>
</template>

<script>
import {unConfirmStatement, listDealerStatement} from './api/DealerStatementApi'
import { columns } from './thead/colums'
import { mixin } from './components/mixin/insert.js'
// import {getBillRemind} from "@/components/initModal/api/api";
import {checkPermission} from "@/utils/permissions";
export default {
  name: 'financeReceipt',
  mixins: [mixin],
  components: {
    Overview: () => import('./components/table/overview.vue'),
    ZJAccountInfo: () => import('./components/info/ZJAccountInfo.vue'),
    XYAccountInfo: () => import('./components/info/XYAccountInfo.vue'),
    QKAccountInfo: () => import('./components/info/QKAccountInfo.vue'),
    AccountCheckModal: () => import('./components/accountCheckModal'),
    Confirm: () => import('./components/handler/confirm.vue'),
    Drawer: () => import('./components/handler/drawer.vue'),
    OperateLog: () => import('./components/OperateLog.vue')
  },
  data() {
    return {
      routerStatus: true,
      isShowCarousel: true,
      columns: columns,
      tableData: [],
      tableLoading: false, //表格loading
      visible: false,
      loading: false,
      loadingAll: false,
      spinning: false,
      isCheck: checkPermission('firm:statement:list:check'),
      isLog: checkPermission('firm:statement:list:log'),
      isConfirm: checkPermission('firm:statement:list:confirm'),
      isUnConfirm: checkPermission('firm:statement:list:unConfirm'),
    }
  },
  filters: {
    formatterStatus: function(row) {
      if (new Date(row.finalTime).getTime() < new Date().getTime()) {
        return '已逾期';
      } else {
        return '待确认';
      }
    },
    formatterAudit: function (row) {
      if (row.confirmStatus == 2) {
        return '待处理';
      } else {
        return '待确认';
      }
    }
  },
  mounted() {
    document.addEventListener('mouseup', (event) => {
      const routerName = this.$route.name
      if (routerName === 'accountStatementList') {
      } else {
        // this.$router.push({
        //   name: 'accountStatementList',
        // })
      }
    })
  },
  // beforeRouteEnter(to, from, next) {
  //   if (true) {
  //     console.log('组件内路由')
  //     next((vm) => {
  //       vm.$notification.open({
  //         message: '您有未对账的账单!!!',
  //         description: '对账完成后会解除限制.',
  //         key,
  //         onClose: close,
  //       })
  //       // vm.$router.replace(from.path) //返回之前页面
  //       next()
  //     })
  //   } else {
  //     next((vm) => {})
  //   }
  // },

  beforeRouteLeave(to, from, next) {
    if (this.routerStatus) {
      next()
    } else {
      this.openNotification()
    }
  },
  methods: {
    openNotification() {
      const key = `open${Date.now()}`
      this.$notification.open({
        message: '您有未对账的账单',
        description: '对账完成后解除锁定',
        icon: <a-icon type="smile" style="color: #108ee9" />,
        key,
        onClose: close,
      })
    },
    handlerTableClick(type,row) {
      if (type === 'TextRed') {
        if (this.$refs.QKAccountInfo) {
          this.$refs.QKAccountInfo.init(row)
        }
      }
      if (type === 'ShallowGreen') {
        if (this.$refs.ZJAccountInfo) {
          this.$refs.ZJAccountInfo.init(row)
        }
      }
      if (type === 'ShallowGreenXY') {
        if (this.$refs.XYAccountInfo) {
          this.$refs.XYAccountInfo.init(row)
        }
      }
      if (type === 'DarkBule') {
        if (this.$refs.overview) {
          this.$refs.overview.setRowData(row)
        }
      }
    },
    hanlderTest(name) {
      const _this = this
      if (_this.selectedRows.length === 0) {
        return _this.$message.warning('请选择一条记录')
      }
      if (name === 'AddToExamine') {
        _this.$refs.accountCreditModal.setRowData({}, 'AddToExamine')
        return
      }
      if (name === 'check') {
        if (_this.selectedRows.length === 1) {
          _this.$refs.accountCheckModal.setRowData(_this.selectedRows[0], 'check')
          return
        } else {
          _this.$message.warning('请选择一条记录')
        }
      }
      if (name === 'confirm') {
        if (_this.selectedRows.length === 1) {
          if (_this.$refs.confirmDom) {
            _this.$refs.confirmDom.init(this.selectedRows[0], 'confirm')
          }
          return
        } else {
          _this.$message.warning('请选择一条记录')
        }
      }
      if (name === 'unConfirm') {
        const everyResult = this.selectedRows.every((item) => {
          return item.flagConfirm && item.auditStatus != 2 && item.auditStatus != 3
        })
        if (everyResult) {
          _this.$confirm({
            title: '撤销警告',
            content: '确认要撤销此条对账单的确认吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const vo = []
              _this.selectedRows.forEach((e) => {
                vo.push(e.id)
              })
              const params = {
                statementIds:vo
              }
              unConfirmStatement(params).then(res => {
                if (res.code === 200) {
                  _this.$message.success(res.message)
                  _this.$bus.$emit('getList')
                  _this.getData()
                } else {
                  _this.$message.error(res.message)
                }
              })
            },
            onCancel() {},
          })
          return
        } else {
          this.$message.warning('请选择已确认未审核的对账单')
        }
      }
      if (name === 'checkLog') {
        if (_this.selectedRows.length === 1) {
          _this.$refs.OperateLog.setRowData(this.selectedRows[0])
          return
        } else {
          _this.$message.warning('请选择一条记录')
        }
      }
    },
    //  附件抽屉
    handlerEnlarge(data) {
      // this.isShowCarousel = false
      if (this.$refs.drawer) {
        this.$refs.drawer.init(data)
      }
    },
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      this.selectedRowKeys = []
      this.selectedRows = []
      listDealerStatement({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
            //校验是否有逾期未对账账单
            // getBillRemind().then(({ code, body, message }) => {
            //   if (code === 200 && body > 0) {
            //     this.routerStatus = false
            //   } else {
            //     this.routerStatus = true
            //   }
            // })
          }
        })
        .finally(() => (this.tableLoading = false))
    },
    isImgFile(fileName) {
      console.log(fileName, 'fileName')
      //后缀获取
      let suffix = '';
      // 获取类型结果
      let result = '';
      const flieArr = fileName.split('.');
      suffix = flieArr[flieArr.length - 1];
      if (suffix != '') {
        suffix = suffix.toLocaleLowerCase();
        // 图片格式
        const imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
        // 进行图片匹配
        result = imglist.find(item => item === suffix);
        if (result) {
          return true;
        }
      }
      return false;
    },
  },
}
</script>

<style lang="less" scoped>
@import './style/main.less';
.img-box {
  width: 80px;
  height: 80px;
}
.img-url {
  object-fit: cover;
}
</style>
